const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
}

const auth = {
    login: baseUrl + 'users/login/',
    status: baseUrl + 'users/login-status/',
    ChangePassword: baseUrl + 'users/setPassword'
};

const cart = {
    cartdetails: baseUrl + 'cart/cart-details/',
    removeCart: baseUrl + 'cart/remove/',
    addCard: baseUrl + 'cart/add-to-cart/'
};
const users = {
    create: baseUrl + 'users/create/',
    update: baseUrl + 'users/update/',
    delete: baseUrl + 'users/delete/',
    list: baseUrl + 'users/all/'
};
const userAddress = {
    create: baseUrl + 'user-address/create/',
    update: baseUrl + 'user-address/update/',
    delete: baseUrl + 'user-address/delete/',
    list: baseUrl + 'user-address/all/',
    details: baseUrl + 'user-address/user-details/'
};
const product = {
    create: baseUrl + 'product/create/',
    update: baseUrl + 'product/update/',
    delete: baseUrl + 'product/delete/',
    list: baseUrl + 'product/all/'
};
const productImages = {
    create: baseUrl + 'product/add-product-image/',
    delete: baseUrl + 'product/remove-product-image/',
    list: baseUrl + 'product/product-image-list/'
};
const shopProduct = {
    create: baseUrl + 'shop-product/create/',
    update: baseUrl + 'shop-product/edit-shop-product',
    delete: baseUrl + 'shop-product/delete/',
    list: baseUrl + 'shop-product/shop-details/',
    productlist: baseUrl + 'shop-product/product-filter-list/',
    EnableOrDisable: baseUrl + 'shop-product/disable/',
    shopproductlist: baseUrl + 'shop-product/shop-product-list/',
    offerProductList: baseUrl + 'shop-product/shop-offer-list/',
    shopProductAdminList: baseUrl + 'shop-product/shop-product-admin',
    shopdetailsadmin: baseUrl + 'shop-product/shop-details-admin/'
};
const shopOrder = {
    addtoorder: baseUrl + 'orders/add-to-order-details/',
    addtoorder1: baseUrl + 'orders/add-to-order-items/',
    update: baseUrl + 'orders/update-status/',
    delete: baseUrl + '',
    list: baseUrl + 'orders/list-order',
    orderdetails: baseUrl + 'orders/order-details/',
    placeorder: baseUrl + 'orders/place-order',
    RemoveItem: baseUrl + 'orders/remove-cart'
};
const category = {
    categoryList: baseUrl + 'categories/list/'
};

const NewAPIs = {
    DisplaysProduct: baseUrl + 'v2/product/product-details/',
    AddToCart: baseUrl + 'v2/product/add-to-cart/',
    RemoveFromCart: baseUrl + 'v2/product/remove-cart/',
    MasterCartDetails: baseUrl + 'v2/product/cart-details/'
};
export default {
    users,
    userAddress,
    product,
    productImages,
    shopProduct,
    shopOrder,
    auth,
    cart,
    category,
    NewAPIs
};
