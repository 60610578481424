// Layout //
import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');

// Default //
const ShopCatalog = () => import('../views/Consumer/Shops/ShopCatalog');
const ShopOfferCatalog = () => import('../views/Consumer/Shops/ShopOfferCatalog');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    children: [
        {
            path: '/',
            name: 'ShopCatalog',
            component: ShopCatalog,
            beforeEnter: guards.statusGuard
        },
        {
            path: '/offers/',
            name: 'ShopOfferCatalog',
            component: ShopOfferCatalog,
            beforeEnter: guards.statusGuard
        }
    ]
};
